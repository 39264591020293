import enCover1 from "./en/1.jpg"
import enCover2 from "./en/2.jpg"
import enCover3 from "./en/3.jpg"
import enCover4 from "./en/4.jpg"
import enCover5 from "./en/5.jpg"
import enCover6 from "./en/6.jpg"

import deCover1 from "./de/1.png"
import deCover2 from "./de/2.png"
import deCover3 from "./de/3.png"
import deCover4 from "./de/4.png"
import deCover5 from "./de/5.png"
import deCover6 from "./de/6.png"

import hrCover1 from "./hr/1.png"
import hrCover2 from "./hr/2.png"
import hrCover3 from "./hr/3.png"
import hrCover4 from "./hr/4.png"
import hrCover5 from "./hr/5.png"
import hrCover6 from "./hr/6.png"

import ptCover1 from "./pt/1.png"
import ptCover2 from "./pt/2.png"
import ptCover3 from "./pt/3.png"
import ptCover4 from "./pt/4.png"
import ptCover5 from "./pt/5.png"
import ptCover6 from "./pt/6.png"

import itCover1 from "./it/1.png"
import itCover2 from "./it/2.png"
import itCover3 from "./it/3.png"
import itCover4 from "./it/4.png"
import itCover5 from "./it/5.png"
import itCover6 from "./it/6.png"

import esCover1 from "./es/1.png"
import esCover2 from "./es/2.png"
import esCover3 from "./es/3.png"
import esCover4 from "./es/4.png"
import esCover5 from "./es/5.png"
import esCover6 from "./es/6.png"

import lvCover1 from "./lv/1.png"
import lvCover2 from "./lv/2.png"
import lvCover3 from "./lv/3.png"
import lvCover4 from "./lv/4.png"
import lvCover5 from "./lv/5.png"
import lvCover6 from "./lv/6.png"

export default {
  en: {
    cover1: enCover1,
    cover2: enCover2,
    cover3: enCover3,
    cover4: enCover4,
    cover5: enCover5,
    cover6: enCover6,
  },
  de: {
    cover1: deCover1,
    cover2: deCover2,
    cover3: deCover3,
    cover4: deCover4,
    cover5: deCover5,
    cover6: deCover6,
  },
  es: {
    cover1: esCover1,
    cover2: esCover2,
    cover3: esCover3,
    cover4: esCover4,
    cover5: esCover5,
    cover6: esCover6,
  },
  hr: {
    cover1: hrCover1,
    cover2: hrCover2,
    cover3: hrCover3,
    cover4: hrCover4,
    cover5: hrCover5,
    cover6: hrCover6,
  },
  pt: {
    cover1: ptCover1,
    cover2: ptCover2,
    cover3: ptCover3,
    cover4: ptCover4,
    cover5: ptCover5,
    cover6: ptCover6,
  },
  it: {
    cover1: itCover1,
    cover2: itCover2,
    cover3: itCover3,
    cover4: itCover4,
    cover5: itCover5,
    cover6: itCover6,
  },
  lv: {
    cover1: lvCover1,
    cover2: lvCover2,
    cover3: lvCover3,
    cover4: lvCover4,
    cover5: lvCover5,
    cover6: lvCover6,
  },
}
