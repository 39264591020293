import React from "react"
import { FormattedMessage } from "react-intl"
import Waves from "../../waves"
import covers from "../../../assets/blended-course/course-covers/covers"
import { Link, navigate } from "gatsby"

function BlendedCoursePageComponent({ langKey }) {
  const [selectedCourse, setSelectedCourse] = React.useState(1)
  const [angle, setAngle] = React.useState(0)

  function getCourseNumber(number) {
    if (number <= 0) return 6
    if (number >= 7) return 1
    return number
  }
  function gallerySpin(sign) {
    if (!sign) {
      setAngle(angle + 60)
    } else {
      setAngle(angle - 60)
    }
  }
  return (
    <>
      <section className=" pt-32 pb-24">
        <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="container max-w-5xl mx-auto m-8 leading-relaxed">
            <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
              <FormattedMessage id="BLENDED COURSE" />
            </h2>
            <div className="w-full mb-4">
              <div className="h-1 mx-auto gradient w-64 opacity-90 my-0 py-0 rounded-t"></div>
            </div>
          </div>
        </div>
      </section>
      <Waves />
      <section id="the-project" className="bg-white text-black border-b py-8">
        <div className="container max-w-md mx-auto m-8">
          <div id="gallery">
            <figure
              id="spinner"
              style={{
                transform: "rotateY(" + angle + "deg)",
                WebkitTransform: "rotateY(" + angle + "deg)",
              }}
            >
              <img
                src={covers[langKey].cover1}
                className="cursor-pointer"
                onClick={() => navigate("module/1")}
                alt="Go to Module 1"
              />

              <img
                src={covers[langKey].cover2}
                className="cursor-pointer"
                onClick={() => navigate("module/2")}
                alt="Go to Module 2"
              />
              <img
                className="cursor-pointer"
                onClick={() => navigate("module/3")}
                src={covers[langKey].cover3}
                alt="Go to Module 3"
              />
              <img
                className="cursor-pointer"
                onClick={() => navigate("module/4")}
                src={covers[langKey].cover4}
                alt="Go to Module 4"
              />
              <img
                className="cursor-pointer"
                onClick={() => navigate("module/5")}
                src={covers[langKey].cover5}
                alt="Go to Module 5"
              />
              <img
                className="cursor-pointer"
                onClick={() => navigate("module/6")}
                src={covers[langKey].cover6}
                alt="Go to Module 6"
              />
            </figure>
          </div>
          <button
            className="float-left"
            onClick={() => {
              gallerySpin("-")
              setSelectedCourse(getCourseNumber(selectedCourse - 1))
            }}
          >
            ◀
          </button>
          <button
            className="float-right"
            onClick={() => {
              gallerySpin("")
              setSelectedCourse(getCourseNumber(selectedCourse + 1))
            }}
          >
            ▶
          </button>
        </div>
      </section>
    </>
  )
}

export default BlendedCoursePageComponent
