import * as React from "react"
import Layout from "../../components/layout"
import messages from "../../i18n/pt"
import BlendedCourse from "../../components/pageComponents/blendedCourse"

const BlendedCoursePage = ({ location }) => (
  <Layout location={location} messages={messages}>
    <BlendedCourse langKey="pt" />
  </Layout>
)

export default BlendedCoursePage
